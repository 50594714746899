import React, { useCallback, useState } from 'react'

const Contact = ({ onSubmit, showSubmissionSuccess }) => {
  const [state, setState] = useState({
    busy: false,
    email: '',
    message: '',
    name: '',
  })

  const handleChange = useCallback(
    (field, value) => {
      setState(curr => ({ ...curr, [field]: value }))
    },
    [setState]
  )
  const handleClear = useCallback(
    () => setState({ email: '', message: '', name: '' }),
    []
  )
  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      setState(curr => ({ ...curr, busy: true }))

      onSubmit(state)
    },
    [onSubmit, state]
  )

  return (
    <section id="contact">
      <div className="inner">
        <section>
          {!showSubmissionSuccess && (
            <form onSubmit={handleSubmit}>
              <div className="field half first">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={state.name}
                  onChange={event => handleChange('name', event.target.value)}
                />
              </div>
              <div className="field half">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  value={state.email}
                  onChange={event => handleChange('email', event.target.value)}
                />
              </div>
              <div className="field">
                <label htmlFor="message">Message</label>
                <textarea
                  name="message"
                  id="message"
                  rows="6"
                  value={state.message}
                  onChange={event =>
                    handleChange('message', event.target.value)
                  }
                ></textarea>
              </div>
              <ul className="actions">
                <li>
                  <input
                    type="submit"
                    value="Send Message"
                    className="special"
                    disabled={state.busy}
                  />
                </li>
                <li>
                  <input
                    type="reset"
                    value="Clear"
                    disabled={state.busy}
                    onClick={handleClear}
                  />
                </li>
              </ul>
            </form>
          )}
          {showSubmissionSuccess && (
            <div className="contact-method">
              <span className="icon alt fa-thumbs-up"></span>
              <h4 className="blue">Message sent, we'll be in contact soon.</h4>
            </div>
          )}
        </section>
        <section className="split">
          <section>
            <div className="contact-method">
              <span className="icon alt fa-envelope"></span>
              <h3>Email</h3>
              <a href="mailto:support@redibiscomputing.com">
                support@redibiscomputing.com
              </a>
            </div>
          </section>
          {/*
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>Phone</h3>
                        <span>(000) 000-0000 x12387</span>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-home"></span>
                        <h3>Address</h3>
                        <span>1234 Somewhere Road #5432<br />
                        Nashville, TN 00000<br />
                        United States of America</span>
                    </div>
                </section>
                */}
        </section>
      </div>
    </section>
  )
}

export default Contact
