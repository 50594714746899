import React from 'react'

const Footer = props => (
  <footer id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a
            href="https://github.com/Red-Ibis-Computing"
            aria-label="GitHub"
            className="icon alt fa-github"
          >
            <span className="label">GitHub</span>
          </a>
        </li>
      </ul>
      <ul className="copyright">
        <li>&copy; 2021</li>
        <li>
          Design: <a href="https://html5up.net">HTML5 UP</a>
        </li>
      </ul>
    </div>
  </footer>
)

export default Footer
